import '@fontsource/roboto';
import {
    getAppConfig,
    MUI_LICENSE_KEY,
    ProvideAuth,
    ProvideErrorBoundary,
    ProvideQueryClient,
    ProvideService,
    ProvideSnackbar,
    ProvideTheme,
} from '@insight2profit/drive-app';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import { getBrowserAuthClients } from '@price-for-profit/micro-services';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ROUTES } from 'routing';
import { PERMISSIONS } from 'shared/constants';
import { AppConfig } from 'shared/types';
import { createServiceContext } from 'startup';
import App from './App';
import './index.css';

const run = async () => {
    const baseUrl = process.env.PUBLIC_URL;
    const appConfig = await getAppConfig<AppConfig>(baseUrl);
    const { clientApp, httpClient } = getBrowserAuthClients({
        clientId: appConfig.appId,
        authDomainHost: appConfig.authDomainHost,
        authorityUrl: appConfig.authorityUrl,
        cacheLocation: 'sessionStorage',
        serviceBaseUrl: appConfig.serviceBaseUrl,
        redirectUrl: baseUrl,
        clientScopes: {
            auth: appConfig.authorizationAppId,
            pbi: appConfig.powerBiAppId,
            event: appConfig.eventAppId,
        },
    });

    LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

    const serviceContext = createServiceContext(appConfig, httpClient);

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <BrowserRouter basename={baseUrl}>
                <ProvideTheme>
                    <ProvideSnackbar>
                        <ProvideErrorBoundary>
                            <ProvideService context={serviceContext}>
                                <ProvideAuth
                                    clientApp={clientApp}
                                    unauthorizedRoute={ROUTES.unauthorized}
                                    loginPermission={PERMISSIONS.login}
                                >
                                    <ProvideQueryClient>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <App />
                                        </LocalizationProvider>
                                    </ProvideQueryClient>
                                </ProvideAuth>
                            </ProvideService>
                        </ProvideErrorBoundary>
                    </ProvideSnackbar>
                </ProvideTheme>
            </BrowserRouter>
        </React.StrictMode>
    );
};

run().catch(console.error);
