import {
    AccountInfo,
    AuthenticationResult,
    AuthError,
    EventMessage,
    EventType,
    IPublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { ClientId, LoginPermissionKey } from '../constants';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from '../startup';
import { useAuthState } from '../stores';
import { DefaultUserInfo, InsightB2CClaims } from '../types';
import { getAppPermissions } from '../utility';

type AuthProviderProps = {
    clientApp: IPublicClientApplication;
    unauthorizedRoute: string;
    loginPermission: `${ClientId}.${LoginPermissionKey}`;
    getUserInfo?: (user: DefaultUserInfo) => app.UserInfo;
};

const getDefaultUserInfoFromIdClaims = ({
    given_name,
    family_name,
    name,
    email,
    sub,
    InsightClaims,
}: Record<string, string>): DefaultUserInfo => ({
    firstName: given_name,
    lastName: family_name,
    email,
    displayName: name,
    id: sub,
    insightClaims: InsightClaims,
});

export function ProvideAuth({
    children,
    clientApp,
    unauthorizedRoute,
    loginPermission,
    getUserInfo,
}: React.PropsWithChildren<AuthProviderProps>) {
    const navigate = useNavigate();
    const authState = useAuthState();
    const handleError = useErrorHandler();
    const { enqueueSnackbar } = useSnackbar();

    const handleUserAccount = React.useCallback(
        (account: AccountInfo) => {
            clientApp.setActiveAccount(account);
            const defaultUser = getDefaultUserInfoFromIdClaims(account.idTokenClaims as InsightB2CClaims);
            const appPermissions = getAppPermissions(defaultUser.insightClaims);
            if (!appPermissions.includes(loginPermission)) {
                navigate(unauthorizedRoute);
            }

            if (getUserInfo) {
                authState.user.set(getUserInfo(defaultUser));
            } else {
                authState.user.set(defaultUser);
            }
        },
        [navigate, authState]
    );

    React.useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        clientApp.setNavigationClient(navigationClient);
        const accounts = clientApp.getAllAccounts();
        if (accounts.length > 0) {
            const account = accounts[0];
            handleUserAccount(account);
        }

        const callbackId = clientApp.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    const payload = event.payload as AuthenticationResult;
                    // Verify Insight claims here.
                    const account = payload.account;
                    if (account) {
                        handleUserAccount(account);
                    }
                    break;
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    break;
                case EventType.LOGOUT_END:
                    authState.user.set(null);
                    break;
                case EventType.LOGIN_FAILURE:
                case EventType.ACQUIRE_TOKEN_FAILURE:
                case EventType.LOGOUT_FAILURE:
                    const authError = event.error as AuthError;
                    handleError(authError);
                    break;
                default:
                    break;
            }
        });

        return () => {
            if (callbackId) {
                clientApp.removeEventCallback(callbackId);
            }
        };
    }, [clientApp, authState, handleError, enqueueSnackbar]);

    return <MsalProvider instance={clientApp}>{children}</MsalProvider>;
}

export const AuthInProgress = () => (
    <Typography sx={{ textAlign: 'center' }} variant='h4'>
        Authorization in progress...
    </Typography>
);
